import React from 'react';
import './Menu.css';
import svg_plus from './icons/plus.svg'
import svg_minus from './icons/minus.svg'
import svg_success from './icons/success.svg'
import svg_cancel from './icons/cancel.svg'


class Menu extends React.Component {
    
    constructor(props)
    {
        super(props);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.resetApp = this.resetApp.bind(this);
        this.setActivePanel = this.setActivePanel.bind(this);
        this.setPlayerName = this.setPlayerName.bind(this);
        this.reducePlayerCount = this.reducePlayerCount.bind(this);
        this.increasePlayerCount = this.increasePlayerCount.bind(this);
        this.reduceStartingHealth = this.reduceStartingHealth.bind(this);
        this.increaseStartingHealth = this.increaseStartingHealth.bind(this);
        this.state = {players:JSON.parse(JSON.stringify(this.props.players)), resetVisible:false, keyboardHidden:true, toggledPlayer:null, playerToggleTimeout:null, activePanel:0, keyboardShiftToggle:false, keyboardCapsToggle: false, startingHealth:this.props.startingHealth, playerCount:this.props.playerCount};
    
    }


    reduceStartingHealth()
    {
      this.setState({startingHealth : Math.min(Math.max(this.state.startingHealth -5, 10), 100)});
    }

    increaseStartingHealth()
    {
      this.setState({startingHealth : Math.min(Math.max(this.state.startingHealth +5, 10), 100)});
    }

    reducePlayerCount()
    {
      var playerCount = Math.min(Math.max(this.state.playerCount -1, 1), 6);
      this.setState({playerCount : playerCount});
    }

    increasePlayerCount()
    {
      var playerCount = Math.min(Math.max(this.state.playerCount +1, 1), 6);
      this.setState({playerCount : playerCount});
    }

    setActivePanel(panel) {
        this.setState({activePanel:panel})
    }

    resetApp()
    {
        this.props.resetApp();
        this.setState({resetVisible:true, activePanel:0});
        window.location.reload();
    }

    close()
    {
        clearTimeout(this.state.playerToggleTimeout);
        this.props.hideMenu();
        this.setState({players:JSON.parse(JSON.stringify(this.props.players)), resetVisible:false, keyboardHidden:true, toggledPlayer:null, playerToggleTimeout:null, activePanel:0, keyboardShiftToggle:false, keyboardCapsToggle: false, startingHealth:this.props.startingHealth, playerCount:this.props.playerCount});
    }

    save() {
        clearTimeout(this.state.playerToggleTimeout);
        this.props.hideMenu(JSON.parse(JSON.stringify(this.state.players)), this.state.startingHealth, this.state.playerCount);
        this.setState({resetVisible:true, activePanel:0, toggledPlayer:null, keyboardHidden:true, playerToggleTimeout: null});
    }


    togglePlayer(index){
        clearTimeout(this.state.playerToggleTimeout);
        this.setState({toggledPlayer:index,playerToggleTimeout:setTimeout(this.clearToggledPlayer.bind(this),5000)});
    }

    clearToggledPlayer() {
        this.setState({toggledPlayer:null, playerToggleTimeout:null});
    }

    typeName(character) {
        if(this.state.playerToggleTimeout == null) return;
        this.setPlayerName(this.state.toggledPlayer,this.state.players[this.state.toggledPlayer].name === "Player " + (this.state.toggledPlayer +1) ? character : this.state.players[this.state.toggledPlayer].name + character);
        clearTimeout(this.state.playerToggleTimeout);
        this.setState({playerToggleTimeout:setTimeout(this.clearToggledPlayer.bind(this),5000), keyboardShiftToggle:false});
    }

    setPlayerName(index, name) {
        var players = this.state.players;
        players[index].name = name;
        this.setState({players:players});
      }

    clearName() {
        if(this.state.playerToggleTimeout == null) return;
        this.setPlayerName(this.state.toggledPlayer,"Player " + (this.state.toggledPlayer +1));
    }

    backSpace() {
        if(this.state.playerToggleTimeout == null) return;
        let currentName = this.state.players[this.state.toggledPlayer].name;
        let newName = currentName.substring(0,currentName.length - 1);
        if (newName === "") {
            this.setPlayerName(this.state.toggledPlayer,"Player " + (this.state.toggledPlayer +1));
        }else{
            this.setPlayerName(this.state.toggledPlayer,newName);
        }
    }
    toggleShift() {
        this.setState({keyboardShiftToggle:!this.state.keyboardShiftToggle});
    }
    toggleCaps() {
        this.setState({keyboardCapsToggle:!this.state.keyboardCapsToggle});
    }

    togglePlayerPartnerCmdr(playerId)
    {
      var players = this.state.players;
      players[playerId].hasPartnerCommander = !players[playerId].hasPartnerCommander;
      this.setState({players:players});
    }

    render()
    {
        var partnerToggleButtons = [];
        for(var i=0;i<this.state.playerCount;i++)
        {
            partnerToggleButtons.push((
                <button key={i} className={"commanderSelect button" + (this.state.players[i].hasPartnerCommander ? " toggled" : "")} onClick={this.togglePlayerPartnerCmdr.bind(this,i)}>{"P " + (i+1)}</button>
            ));
        }

        var playerNameButtons = [];
        for(var j=0;j<this.state.playerCount;j++)
        {
            playerNameButtons.push((
                <button key={j} className={"playerNameButton button" + (this.state.toggledPlayer === j ? " toggled" : "")} onClick={this.togglePlayer.bind(this,j)}>{this.state.players[j] !== undefined ? this.state.players[j].name : ""}</button>
            ));
        }
        


        var keyboardButtonsLine1 = [];
        var keyboardButtonsLine2 = [];
        var keyboardButtonsLine3 = [];

        var line1 = "qwertyuiop"
        var line2 = "asdfghjkl"
        var line3 = "zxcvbnm"

        if (this.state.keyboardShiftToggle || this.state.keyboardCapsToggle) {
            line1 = line1.toUpperCase()
            line2 = line2.toUpperCase()
            line3 = line3.toUpperCase()
        }

        for(let i = 0; i < line1.length; i++)
        {
            let letter = line1.charAt(i);
            keyboardButtonsLine1.push((<button key={letter} className="keyboardButton button" onClick={this.typeName.bind(this,letter)}>{this.state.keyboardShiftToggle || this.state.keyboardCapsToggle ? letter.toUpperCase() : letter }</button>));
        }
        for(let i = 0; i < line2.length; i++)
        {
            let letter = line2.charAt(i);
            keyboardButtonsLine2.push((<button key={letter} className="keyboardButton button" onClick={this.typeName.bind(this,letter)}>{this.state.keyboardShiftToggle || this.state.keyboardCapsToggle ? letter.toUpperCase() : letter }</button>));
        }
        for(let i = 0; i < line3.length; i++)
        {
            let letter = line3.charAt(i);
            keyboardButtonsLine3.push((<button key={letter} className="keyboardButton button" onClick={this.typeName.bind(this,letter)}>{this.state.keyboardShiftToggle || this.state.keyboardCapsToggle ? letter.toUpperCase() : letter }</button>));
        }
           



        return (      
            <div className={"Menu" + (this.props.isHidden ? " hidden" : "")}>   
                <div className='menu_title'>Arcane Signet Commander Tracker</div>   
                <div className="menu_tab_wrapper">
                    <div className="menu_tab_buttons">
                        <button className={"menu_tab_button" + (this.state.activePanel !== 0 ? " menu_tab_button_inactive" : "")} onClick={this.setActivePanel.bind(this,0)}>General</button>
                        <button className={"menu_tab_button" + (this.state.activePanel !== 1 ? " menu_tab_button_inactive" : "")} onClick={this.setActivePanel.bind(this,1)}>Players</button>
                        <button className={"menu_tab_button" + (this.state.activePanel !== 3 ? " menu_tab_button_inactive" : "")} onClick={this.setActivePanel.bind(this,3)}>Reset</button>
                        <button className={"menu_tab_button" + (this.state.activePanel !== 2 ? " menu_tab_button_inactive" : "")} onClick={this.setActivePanel.bind(this,2)}>About</button>
                    </div>
                    <div className="menu_tab_content_wrapper">
                        <div className={"menu_tab_content" + (this.state.activePanel !== 0 ? " hidden" : "")}>                        
                            <div className='menu_player_count_section'>
                                <div className="leftdivcolumn">Players:</div>
                                <div className='rightdivcolumn'>
                                    <button className='button minusbutton' onClick={this.reducePlayerCount}><img alt='Reduce Player Count' src={svg_minus}/></button>
                                    <div className='counter player_count_counter'>{this.state.playerCount}</div>
                                    <button className='button plusbutton' onClick={this.increasePlayerCount}><img alt='Increase Player Count' src={svg_plus}/></button>
                                </div>
                            </div>
                        
                            <div className='menu_starting_health_section'>
                                <div className="leftdivcolumn">Starting Health:</div>
                                <div className='rightdivcolumn'>
                                    <button className='button minusbutton' onClick={this.reduceStartingHealth}><img alt='Reduce Starting Health' src={svg_minus}/></button>
                                    <div className='counter starting_health_counter'>{this.state.startingHealth}</div>
                                    <button className='button plusbutton' onClick={this.increaseStartingHealth}><img alt='Increase Starting Health' src={svg_plus}/></button>
                                </div>
                            </div>
                            <div>
                                <button className={'button nosleep' + (this.props.noSleepEnabled ? " toggled" : "")} onClick={this.props.noSleepEnabled ? this.props.disableNoSleep : this.props.enableNoSleep}>Keep Screen Awake</button>
                            </div>
                        </div>
                        <div className={"menu_tab_content" + (this.state.activePanel !== 1 ? " hidden" : "")}>
                            <div className='menu_partner_commander_toggle'>
                                Partner Commanders:
                                {partnerToggleButtons}
                            </div>
                            <div className={'menu_player_names'}>
                                {playerNameButtons}
                            </div>
                            <div className={'menu_keyboard' + (this.state.playerToggleTimeout === null ? " hidden" : "")}>
                                <div className="menu_keyboard_line">
                                    {keyboardButtonsLine1}
                                </div>
                                <div className="menu_keyboard_line">
                                    {keyboardButtonsLine2}
                                </div>
                                <div className="menu_keyboard_line">
                                    {keyboardButtonsLine3}
                                </div>
                                <div className="menu_keyboard_modifiers">
                                    <button key="shift" className={"keyboardButton button autowidth" + (this.state.keyboardShiftToggle ? " toggled" : "")} onClick={this.toggleShift.bind(this)}>^</button>
                                    <button key="caps" className={"keyboardButton button autowidth" + (this.state.keyboardCapsToggle ? " toggled" : "")} onClick={this.toggleCaps.bind(this)}>CAPS</button>
                                    <button key="clear" className="keyboardButton button autowidth" onClick={this.clearName.bind(this)}>CLEAR</button>
                                    <button key="backspace" className="keyboardButton button autowidth" onClick={this.backSpace.bind(this)}>&lt;</button>
                                </div>
                                
                            </div>
                        </div>
                        <div className={"menu_tab_content" + (this.state.activePanel !== 2 ? " hidden" : "")}>
                            <div className="menu_tab_info">commander.arcanesignet.com</div>
                        </div>
                        <div className={"menu_tab_content" + (this.state.activePanel !== 3 ? " hidden" : "")}>
                            <div className="menu_tab_info">Reset</div>
                            <button className='button resetbutton' onClick={() => {this.props.resetCounters();this.close();}}><div>Reset Counters</div></button>
                            <button className='button resetbutton' onClick={this.resetApp}><div>Reset App</div></button>
                        </div>
                        <div className={"close_buttons" + (this.state.activePanel === 3 ? " hidden" : "")}>
                            <button className='button successbutton' onClick={this.save}><img alt='Accept' src={svg_success}/><div>Accept</div></button>
                            <button className='button cancelbutton' onClick={this.close}><img alt='Cancel' src={svg_cancel}/><div>Close</div></button>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default Menu;
