import React from 'react';
import './CommanderUsed.css';
import plus from './icons/plus.svg';
import minus from './icons/minus.svg';

class CommanderUsed extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {commanderUsed:0};
    }


    addValue()
    {
        this.props.adjustCommanderUsed(this.props.playerId,this.props.commanderId,1);
    }

    subtractValue()
    {
        if(this.props.commanderUsed > 0)
        {
            this.props.adjustCommanderUsed(this.props.playerId,this.props.commanderId,-1);
        }
    }

    render ()
    {
        return (
            <div className={"commanderUsed flexrow"}>
                <button className="commanderUsedButton" onClick={this.subtractValue.bind(this)}>
                    <img className="buttonimg" src={minus} alt={"Reduce Player " + (this.props.playerId + 1) + " Commander Uses"} draggable="false" />
                </button>
                <div className="commanderUsedCounter">
                    {this.props.commanderUsed}
                </div>
                <button className="commanderUsedButton" onClick={this.addValue.bind(this)}>
                <img className="buttonimg"  src={plus} alt={"Increase Player " + (this.props.playerId + 1) + " Commander Uses"} draggable="false" />

                </button>
            </div>
        )
    }
}

export default CommanderUsed;