import React from 'react';
import './MenuPanel.css';
import D20 from './D20.js'

class MenuPanel extends React.Component {

    render ()
    {
        return (
            <div className={"menupanel players" + this.props.playerCount}>
                <button className="menuPanelButton" onClick={this.props.showSpinnerPanel.bind(this)}>
                    Spin
                </button>
                <button className="menuPanelButton" onClick={this.props.quickPick.bind(this)}>
                    Quick Pick
                </button>
                <D20 />
                <button className="menuPanelButton" onClick={this.props.showMassDamagePanel}>Mass Dmg</button>
                <button className="menuPanelButton" onClick={this.props.showMenu}>Menu</button>
            </div>
        )
    }
}

export default MenuPanel;
