import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


window.addEventListener("contextmenu", function(e) { e.preventDefault(); })

window.addEventListener("touchstart", function onFirstTouch() {
    this.window.USER_TOUCH_ENABLED = true;
    this.window.removeEventListener('touchstart', onFirstTouch, false);
});

ReactDOM.render(<React.StrictMode><App maxPlayers={6} startingHealth={40}/></React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

var config ={};

config.onUpdate = () => {
    var prompt = document.getElementById("updateAvailablePrompt");
    prompt.classList.remove("hidden");
}

serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
