import React from 'react';
import './D20.css';
import svg_d20 from './icons/d20.svg'
import MersenneTwister from 'mersenne-twister';

class D20 extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {number:0, rolling:false, rollTimeout:null, random:new MersenneTwister()};
        
    }


    startRoll()
    {
        clearTimeout(this.state.rollTimeout);
        this.setState({rolling:true});
        setTimeout(this.generateRandomNumber.bind(this,1),50)
    }

	generateRandomNumber(iteration)
	{
		if(iteration < 10)
		{
            this.setState({number:(Math.floor(this.state.random.random() * 20) + 1)}); 
            setTimeout(this.generateRandomNumber.bind(this,iteration + 1),50);
		}else{
            this.setState({rollTimeout:setTimeout(this.stopRoll.bind(this),2000)});
        }
		
    }

    stopRoll()
    {
        this.setState({rolling:false});
    }


    render() {

        var content = [];

        if(this.state.rolling)
        {
            content.push((
                <div key="D20" className="flexcolumn">
                    {this.state.number}
                </div>
            ));
        }else{
            content.push((
                <div key="D20" className="flexcolumn">
                    <img height="100%" alt="Roll D20" src={svg_d20}/>
                </div>
            ));
        }

        return (
            <button className={"menuPanelButton" + (this.state.rolling ? " rolling" : "")} onClick={this.startRoll.bind(this)}>
                {content}
            </button>
        );
    }
}





export default D20;