import React from 'react';
import './MassDamagePanel.css'
import svg_confirm from './icons/success.svg';
import svg_cancel from './icons/cancel.svg'
import plus_health from './icons/plus.svg';
import minus_health from './icons/minus.svg';

class MassDamagePanel extends React.Component {

    constructor(props)
    {
        super(props);

        var newSelectedPlayers = [];
        for(var i=0;i<this.props.players.length;i++)
        {
          newSelectedPlayers[i] = false;
        }

        this.state = {damageDealt:0, selectedPlayers:newSelectedPlayers, healthAdjustRepeatTimer:null};

        window.addEventListener("mouseup",this.stopDamage.bind(this));
        window.addEventListener("touchend", this.stopDamage.bind(this));
    }

    startadjustHealthMouse(value)
    {
      if(window.USER_TOUCH_ENABLED) return;
      clearTimeout(this.state.healthAdjustRepeatTimer);
      this.startadjustHealth.bind(this, value, null)();
    }

    startadjustHealth(value)
    {
      
      clearTimeout(this.state.healthAdjustRepeatTimer);
      this.adjustHealth.bind(this, value, null)();
      this.setState({healthAdjustRepeatTimer : setTimeout(this.adjustHealth.bind(this,value,150),800)})
    }

    adjustHealth(value, interval)
    {  
      this.setState({damageDealt: (this.state.damageDealt + value)});
      if(interval != null)
      {
        clearTimeout(this.state.healthAdjustRepeatTimer);
        this.setState({healthAdjustRepeatTimer : setTimeout(this.adjustHealth.bind(this,value, interval-1),interval -1)})
      }
    }

    stopDamage()
    {
      clearTimeout(this.state.healthAdjustRepeatTimer);
    }


    toggleSelectedPlayer(playerId)
    {
      
        var newSelectedPlayers = this.state.selectedPlayers;
        newSelectedPlayers[playerId] = !this.state.selectedPlayers[playerId];

        this.setState({selectedPlayers: newSelectedPlayers});
    }

    selectAllPlayers()
    {
      var newSelectedPlayers = [];
      for(var i=0;i<this.props.players.length;i++)
      {
        newSelectedPlayers[i] = true;
      }

      this.setState({selectedPlayers:newSelectedPlayers});
    }

    closeMe(cancel)
    {
        if(cancel)
        {
            this.props.close(null,null);
        }else{
            this.props.close(this.state.selectedPlayers, this.state.damageDealt);
        }

        var newSelectedPlayers = [];
        for(var i=0;i<this.props.players.length;i++)
        {
          newSelectedPlayers[i] = false;
        }

        this.setState({damageDealt:0, selectedPlayers:newSelectedPlayers, healthAdjustRepeatTimer:null});
    }

    render(){

        var playerSelectionPanel = [];
  
        var players = this.props.players;
  
        for(var i=0;i<players.length;i++)
        {

          playerSelectionPanel.push((
              <button 
              key={i}
              className={"commanderDamagePanel player" + (i + 1 ) + ((this.state.selectedPlayers[i]) ? " selected" : "")}
              onClick={this.toggleSelectedPlayer.bind(this,i)}>
                    {i+1}
              </button>
          ));
        }

        playerSelectionPanel.push((
          <button 
          key={players.length+1}
          className={"commanderDamagePanel"}
          onClick={this.selectAllPlayers.bind(this)}>
                ALL
          </button>
      ));

        return(
            <div className={"massDamagePanel flexcolumn" + (this.props.hidden ? " hidden" : "")}>
              <div>Mass Damage</div>
                <div className="damageRow flexrow">
                    <button className="healthButton"
                    onMouseDown={this.startadjustHealthMouse.bind(this, -1)}
                    onTouchStart={this.startadjustHealth.bind(this, -1)}>
                        <img src={minus_health} alt={"Deal One Damage"} draggable="false" />
                    </button>
                    <button className="counterButton">
                        {this.state.damageDealt}
                    </button>
                    <button className="healthButton"
                    onMouseDown={this.startadjustHealthMouse.bind(this, 1)}
                    onTouchStart={this.startadjustHealth.bind(this, 1)} >
                    <img src={plus_health} alt={"Deal Minus One Damage"}  draggable="false" />
                    </button>
                </div>
                <div className="commanderSelectionRow flexrow">
                    {playerSelectionPanel}
                </div>
                <div className="confirmRow flexrow">
                    <button className='button cancelbutton' onClick={this.closeMe.bind(this,true)}><img alt='Cancel' src={svg_cancel}/></button>
                    <button className='button successbutton' onClick={this.closeMe.bind(this,false)}><img alt='Confirm' src={svg_confirm}/></button>
                </div>
            </div>
        );
    }
}


export default MassDamagePanel;