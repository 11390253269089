import React from 'react';
import './App.css';
import PlayerSector from './PlayerSector.js'
import Menu from './Menu.js'
import MenuPanel from './MenuPanel.js'
import MassDamagePanel from './MassDamagePanel.js'
import SpinnerPanel from './SpinnerPanel.js'
import Spinner from './Spinner.js'
import MersenneTwister from 'mersenne-twister'
import NoSleep from 'nosleep.js'


class App extends React.Component {

  constructor(props, context)
  {
    super(props, context);
    this.hideMenu = this.hideMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.adjustPlayerDamage = this.adjustPlayerDamage.bind(this);
    this.adjustCommanderDamage = this.adjustCommanderDamage.bind(this);
    this.adjustCommanderUsed = this.adjustCommanderUsed.bind(this);
    this.getCommanderUsed = this.getCommanderUsed.bind(this);
    this.quickPick = this.quickPick.bind(this);
    this.checkLandscape = this.checkLandscape.bind(this);
    this.enableNoSleep = this.enableNoSleep.bind(this);
    this.disableNoSleep = this.disableNoSleep.bind(this);
    this.setupDefaultState = this.getDefaultState.bind(this);
    this.resetApp = this.resetApp.bind(this);
    this.resetCounters = this.resetCounters.bind(this);
    this.nosleep = new NoSleep();

    if(!localStorage.getItem('state')) {
      console.log("Setting up default state...")
      this.state = this.getDefaultState();
    }else{
      if (localStorage.getItem('state') === 'undefined') {
        this.state = this.getDefaultState();
      }else {
        this.state = JSON.parse(localStorage.getItem('state'));
      }
    }

    this.state.menuHidden = false;
    this.state.noSleepEnabled = false;
    this.state.landscape =  document.documentElement.clientHeight < document.documentElement.clientWidth;

    window.onresize = this.checkLandscape;


  }
  
  writeStateToStorage() {
    localStorage.setItem('state', JSON.stringify(this.state));
  }

  getDefaultState() {
    var playerPartnerCmdrArr = [];
    var playerArr = [];

    for(var i=0;i<6;i++)
    {
      playerPartnerCmdrArr[i] = false;
      
      var player = {};
      player.hasPartnerCommander = false;
      player.damage = 0;
      player.commanders = [];
      player.commanders[0] = {};
      player.commanders[0].used = 0;
      player.commanders[1] = {};
      player.commanders[1].used = 0;
      player.commanderDamage = [];
      player.highlighted = false;
      player.name = "Player " + (i+1);

      for(var j=0;j<6;j++)
      {
        player.commanderDamage[j] = {};
        player.commanderDamage[j].commanders = [];
        player.commanderDamage[j].commanders[0] = 0;
        player.commanderDamage[j].commanders[1] = 0;
      }

      playerArr[i] = player;
    }

    return {
      maxPlayers: this.props.maxPlayers,
      menuHidden: false,
      startingHealth: this.props.startingHealth,
      players:playerArr,
      massDamagePanelHidden:true,
      spinnerPanelHidden:true,
      spinning:false,
      spinnerSelectedPlayers:null,
      highlightTimeout:null,
      landscape:false,
      noSleepEnabled: false
      };
  }


  enableNoSleep() {
    this.nosleep.enable()
    this.setState({noSleepEnabled:true})
  }

  disableNoSleep() {
    this.nosleep.disable()
    this.setState({noSleepEnabled:false})
  }

  checkLandscape() {
    var landscape = document.documentElement.clientHeight < document.documentElement.clientWidth;
    this.setState({landscape:landscape});
  }
  resetCounters()
  {
    var players = this.state.players;
    for(var i=0;i<players.length;i++)
    {
      players[i].damage = 0;
      for(var j=0;j<players[i].commanders.length;j++)
      {
        players[i].commanders[j].used = 0;
      }
      for(var k=0;k<players[i].commanderDamage.length;k++)
      {
        for(var m=0;m<players[i].commanderDamage[k].commanders.length;m++)
        {
          players[i].commanderDamage[k].commanders[m] = 0;
        }
      }
    }
    this.setState({players:players});
  }

  getCommanderDamage(playerId,srcPlayerId,commanderId)
  {
    var players = this.state.players;

    return players[playerId].commanderDamage[srcPlayerId].commanders[commanderId];
  }

  adjustPlayerDamage(playerId,value)
  {
    var players = this.state.players;
    players[playerId].damage += value;
    this.setState({players:players});
  }

  adjustCommanderDamage(playerId,srcPlayerId,commanderId,value)
  {
    var players = this.state.players;
    players[playerId].commanderDamage[srcPlayerId].commanders[commanderId] += value;
    this.setState({players:players});
  }

  adjustCommanderUsed(playerId,commanderId,value)
  {
    var players = this.state.players;
    players[playerId].commanders[commanderId].used += value;
    this.setState({players:players});
  }

  getCommanderUsed(playerId,commanderId)
  {
    var players = this.state.players;
    return players[playerId].commanders[commanderId].used;
  }

  hideMenu(players, startingHealth, maxPlayers)
  {

    var newPlayers = (players === null || players === undefined) ? this.state.players : players;
    var newStartingHealth = (startingHealth === null || startingHealth === undefined) ? this.state.startingHealth : startingHealth;
    var newMaxPlayers = (maxPlayers === null || maxPlayers === undefined) ? this.state.maxPlayers : maxPlayers;

    this.setState({menuHidden : true, players:newPlayers, startingHealth: newStartingHealth, maxPlayers: newMaxPlayers});
  }

  showMenu()
  {
    this.setState({menuHidden : false, workingPlayers:JSON.parse(JSON.stringify(this.state.players))});
  }


  getPlayerCount()
  {
    return this.state.maxPlayers;
  }

  getPlayerName(index){
    return this.state.players[index].name
  }

  getCommanderArray()
  {
    return this.state.commanders;
  }

  closeSpinnerPanel(selectedPlayers)
  {
    var spinning = true
    if(selectedPlayers != null)
    { 
      this.setState({spinnerSelectedPlayers:selectedPlayers});
    }else{
      spinning = false;
    }
    this.setState({spinning:spinning, spinnerPanelHidden:true});
  }

  setSpinning(spinning)
  {
    this.setState({spinning:spinning});
  }

  closeMassDamagePanel(selectedPlayers, value)
  {
    if(value !== null && value !== 0)
    {
      var players = this.state.players;

      for(var i=0;i<players.length;i++)
      {
        if(selectedPlayers[i])
        {
          players[i].damage += value;
        }
      }
      this.setState({players:players});
    }
    this.setState({massDamagePanelHidden:true});
  }

  showMassDamagePanel()
  {
    this.setState({massDamagePanelHidden:false});
  }
  
  showSpinnerPanel()
  {
    if(this.state.maxPlayers === 2) {
      this.setState({spinnerSelectedPlayers:[true,true], spinning:true});
    }else {
      this.setState({spinnerPanelHidden:false});
    }
  }

  clearHighlights() {
    var players = this.state.players;


    for(var i=0;i<players.length;i++)
    {
      players[i].highlighted = false;
    }

    this.setState({players:players,highlightTimeout:null});
  }

  quickPick() {
    if(this.state.highlightTimeout != null) return;

    var players = this.state.players;

    var generator = new MersenneTwister();

    var pickedPlayer = Math.floor(generator.random() * this.state.maxPlayers);
    console.log(pickedPlayer);
    console.log(players.length);
    
    players[pickedPlayer].highlighted = true;

    clearTimeout(this.state.highlightTimeout);
    this.setState({players:players, highlightTimeout:setTimeout(this.clearHighlights.bind(this),2500)});   
    
  }

   resetApp() {
    this.setState(this.getDefaultState());
    console.log("Resetting App...")
  }

  setUpChildren()
  {
      var startingHealth = parseInt(this.state.startingHealth);

      var content = [];

      if(this.state.spinning)
      {
        content.push(
          <Spinner key="Spinner" selectedPlayers={this.state.spinnerSelectedPlayers} players={this.state.players} close={this.setSpinning.bind(this, false)}/>
        );
      }


      content.push(<MassDamagePanel 
                    key="MassDamagePanel"
                    close={this.closeMassDamagePanel.bind(this)}
                    hidden={this.state.massDamagePanelHidden}
                    players={this.state.players}
      />);
      if(!this.state.spinnerPanelHidden)
      {
        content.push(<SpinnerPanel 
          key="SpinnerPanel"
          close={this.closeSpinnerPanel.bind(this)}
          hidden={this.state.spinnerPanelHidden}
          players={this.state.players}
          maxPlayers={this.state.maxPlayers}
        />); 
      }
     

      content.push(<Menu 
                    key='-1'
                    isHidden={this.state.menuHidden}
                    hideMenu={this.hideMenu}
                    players={this.state.players}
                    playerCount={this.state.maxPlayers}
                    startingHealth={this.state.startingHealth}
                    enableNoSleep={this.enableNoSleep}
                    disableNoSleep={this.disableNoSleep}
                    noSleepEnabled={this.state.noSleepEnabled}
                    resetApp={this.resetApp}
                    resetCounters={this.resetCounters}
                    />)

      
      for(var i=0;i<this.state.maxPlayers;i++)
      {
        content.push(<PlayerSector 
          key={i} 
          playerId={i} 
          startingHealth={startingHealth} 
          players={this.state.players}
          maxPlayers={this.state.maxPlayers}
          adjustHealth={this.adjustPlayerDamage}
          adjustCommanderDamage={this.adjustCommanderDamage}
          adjustCommanderUsed={this.adjustCommanderUsed}
          />)
      }

      content.push(<MenuPanel 
        key={this.state.maxPlayers + 1} 
        playerCount={this.state.maxPlayers}
        showMenu={this.showMenu.bind(this)}
        showMassDamagePanel={this.showMassDamagePanel.bind(this)}
        showSpinnerPanel={this.showSpinnerPanel.bind(this)}
        quickPick={this.quickPick.bind(this)}
        />)

      content.push(<button key="updatePrompt" id="updateAvailablePrompt" className="hidden" onClick={() => {
        var e = document.getElementById("updateAvailablePrompt");
        e.classList.add("hidden")
      }}>An update is available. Close and reopen the app to update. Click this banner to dismiss.</button>);



      return content;
  }


  componentDidMount() {
    this.checkLandscape();
  }

  componentDidUpdate() {
    this.writeStateToStorage();
  }

  render(){
      if (this.state === null || this.state === undefined) {
        return (<div>Loading...</div>);
      }

      return (
        <div className={this.state.landscape ? "applandscape" : "appportrait"}>
          {this.setUpChildren()}
        </div>
      );
    };
}



export default App;
