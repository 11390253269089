import React from 'react';
import './Spinner.css';
import MersenneTwister from 'mersenne-twister';






class Spinner extends React.Component {

    constructor(props)
    {
        super(props);
        var totalSelected = 0;
        for(var h=0;h<this.props.selectedPlayers.length;h++)
        {
            if(this.props.selectedPlayers[h]) totalSelected++;
        }
        this.state = {spinterval:null, spinning:true, selectedNumber:0, currentRotation:0, maxRotation:totalSelected * 16, totalSelected: totalSelected};
    }

    componentDidMount() 
    {
        setTimeout(this.startRender.bind(this),100);
    }

    startRender()
    {
        var interval = 16.67; // 60fps
        this.setState({spinterval:setInterval(this.renderSpinner.bind(this),interval)});        
        setTimeout(this.stopSpinning.bind(this),interval * this.state.maxRotation);
    }

    stopSpinning()
    {
        //pick a player
        var twister = new MersenneTwister();
        var randomNumber = Math.floor(twister.random() * this.state.totalSelected);
        randomNumber = (randomNumber * 16) + 8;
        var newRandomNumber = Math.floor(twister.random() * 12) - 6;
        this.setState({spinning:false, selectedNumber:randomNumber + newRandomNumber});
    }

    stopRender()
    {
        clearInterval(this.state.spinterval);
        setTimeout(this.props.close,2000);
    }

    renderSpinner()
    {
        var currentRotation = (this.state.currentRotation + 1) % this.state.maxRotation;
        this.setState({currentRotation:currentRotation});


        var canvas = document.getElementById("spinnerCanvas");
        if(canvas === null) {
            clearInterval(this.state.spinterval);
            return;
        }
        var context = canvas.getContext("2d");

        context.clearRect(0,0,1000,1000);
        context.save();

        context.translate(500,500);
        context.rotate((Math.PI * 2) / this.state.maxRotation * currentRotation);
        context.translate(-500,-500);


        context.beginPath();
        context.lineTo(0,500);
        context.lineTo(530, 450);
        context.lineTo(560, 500);
        context.lineTo(530, 550);
        context.closePath();
        context.fillStyle = 'black';
        context.fill();
        context.lineWidth = 5;
        context.strokeStyle = 'white';
        context.stroke();

        context.beginPath(); 
        context.fillStyle = 'white';   
        context.arc(500, 500, 20, 0, Math.PI * 2);
        context.fill();
        context.restore();

        if(this.state.spinning === false)
        {
            if(this.state.currentRotation === this.state.selectedNumber)
            {
                clearInterval(this.state.spinterval);
                setTimeout(this.props.close,2000);
                return; 
            }
        }

    }

    getCoordinatesForPercent(percent) {
        const x = Math.cos(2 * Math.PI * percent);
        const y = Math.sin(2 * Math.PI * percent);
        
        return [x, y];
    }

    render ()
    {

        //The render function just renders the backboard, the renderSpinner() function handles the spinner (needle) itself
        var slices = [];
        var section = 0;
        var colors = ["darkorchid", "chocolate", "darkcyan", "khaki", "firebrick", "lightskyblue"];

        for(var i=0;i<this.props.selectedPlayers.length;i++)
        {
            if(this.props.selectedPlayers[i])
            {   
                let startAngle = 2*Math.PI*(section/this.state.totalSelected);
                let xPosStart = Math.cos(startAngle);
                let yPosStart = Math.sin(startAngle);
                
                let endAngle = 2*Math.PI*((section+1)/this.state.totalSelected);
                let xPosEnd = Math.cos(endAngle);
                let yPosEnd = Math.sin(endAngle);

                let sweep = 1;
                let largeArc = 0;
                
            
                slices.push((<path className={"player" + (i+1)} key={i} d={"M " + xPosStart + " " + yPosStart +" A 1 1 0 " + largeArc  + " " + sweep + " " + xPosEnd + " " + yPosEnd + " L 0 0"} fill={colors[i]}></path>));
                section++;
            }
        }
        section = 0.5;
        for(var j=0;j<this.props.selectedPlayers.length;j++)
        {
            if(this.props.selectedPlayers[j])
            {   
                let startAngle = 2*Math.PI*(section/this.state.totalSelected);
                let xPosStart = Math.cos(startAngle);
                let yPosStart = Math.sin(startAngle);
                            

                let style = {
                    textAnchor:"middle",
                    transform:"rotate(" + (360/this.state.totalSelected*section)  + "deg) rotate(90deg)"
                }
                slices.push((<g key={"text" + j} transform={"translate(" + xPosStart *0.8 + "," + yPosStart *0.8 + ")"}><text style={style} className="svgText">{this.props.players[j].name}</text></g>));
                section++;
            }
        }

        return (
            <div className={"spinner spinnerplayers" + this.props.playerCount}>
                <svg className="backboard" viewBox="-1 -1 2 2" style={this.props.selectedPlayers.length === 2 ? {transform: "rotate(90deg)"} : {}}>
                    {slices}
                </svg>
                <canvas id="spinnerCanvas" width="1000" height="1000" style={this.props.selectedPlayers.length === 2 ? {transform: "rotate(90deg)"} : {}}>

                </canvas>
            </div>
        )
    }
}

export default Spinner;