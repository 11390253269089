import React from 'react';
import './SpinnerPanel.css'
import svg_confirm from './icons/success.svg';
import svg_cancel from './icons/cancel.svg'

class SpinnerPanel extends React.Component {

    constructor(props)
    {
        super(props);

        var newSelectedPlayers = [];
        for(var i=0;i<this.props.maxPlayers;i++)
        {
          newSelectedPlayers[i] = true;
        }

        this.state = {damageDealt:0, selectedPlayers:newSelectedPlayers, healthAdjustRepeatTimer:null};
    }

    toggleSelectedPlayer(playerId)
    {
        var totalSelectedPlayers =0;
        for(var i=0;i<this.state.selectedPlayers.length;i++)
        {
          if(this.state.selectedPlayers[i] === true) totalSelectedPlayers++;
        }

        if(this.state.selectedPlayers[playerId] === true && totalSelectedPlayers === 2) return;

        var newSelectedPlayers = this.state.selectedPlayers;
        newSelectedPlayers[playerId] = !this.state.selectedPlayers[playerId];

        this.setState({selectedPlayers: newSelectedPlayers});
    }

    selectAllPlayers()
    {
      var newSelectedPlayers = [];
      for(var i=0;i<this.props.maxPlayers;i++)
      {
        newSelectedPlayers[i] = true;
      }

      this.setState({selectedPlayers:newSelectedPlayers});
    }

    closeMe(cancel)
    {
      var newSelectedPlayers = [];
      for(var i=0;i<this.props.players.length;i++)
      {
        newSelectedPlayers[i] = true;
      }

      this.setState({selectedPlayers:newSelectedPlayers});

      if(cancel)
      {
          this.props.close(null);
      }else{
          this.props.close(this.state.selectedPlayers);
      }
    }

    render(){

        var playerSelectionPanel = [];
  
        var players = this.props.players;
  
        for(var i=0;i<this.props.maxPlayers;i++)
        {

          playerSelectionPanel.push((
              <button 
              key={i}
              className={"spinnerSelectionButton player" + (i + 1 ) + ((this.state.selectedPlayers[i]) ? " selected" : "")}
              onClick={this.toggleSelectedPlayer.bind(this,i)}>
                    {players[i].name}
              </button>
          ));
        }

        playerSelectionPanel.push((
          <button 
          key={players.length+1}
          className={"spinnerSelectionButton"}
          onClick={this.selectAllPlayers.bind(this)}
          style={{width:"15vmin"}}>
                ALL
          </button>
      ));

        return(
            <div className={"spinnerPanel flexcolumn"}>
              <div>Spinner</div>
                <div className="spinnerSelectionRow flexrow">
                    {playerSelectionPanel}
                </div>
                <div className="confirmRow flexrow">
                  <button className='button successbutton' onClick={this.closeMe.bind(this,false)}><img alt='Accept' src={svg_confirm}/>Accept</button>
                  <button className='button cancelbutton' onClick={this.closeMe.bind(this,true)}><img alt='Close' src={svg_cancel}/>Close</button>
                </div>
            </div>
        );
    }
}


export default SpinnerPanel;
